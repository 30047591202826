import React, { Suspense } from "react";
import { createBrowserRouter } from "react-router-dom";
import { App } from "@/components/App/App";
import { UnauthorizedPage } from "@/pages/unauthorized/UnauthorizedPage";
import {
  LazyBalanceActions,
  LazyFriends,
  LazyPlans,
  LazyTasks,
} from "@/pages/lazy_pages";
import MainPage from "@/pages/main/MainPage";
import { Loader } from "@/components/Loader/Loader";

const routes = [
  {
    element: <App />,
    children: [
      {
        path: "/",
        element: <MainPage />,
      },
      {
        path: "/friends",
        element: (
          <Suspense fallback={<Loader />}>
            <LazyFriends />
          </Suspense>
        ),
      },
      {
        path: "/tasks",
        element: (
          <Suspense fallback={<Loader />}>
            <LazyTasks />
          </Suspense>
        ),
      },
      {
        path: "/out",
        element: (
          <Suspense fallback={<Loader />}>
            <LazyBalanceActions />
          </Suspense>
        ),
      },
      {
        path: "/tariffs",
        element: (
          <Suspense fallback={<Loader />}>
            <LazyPlans />
          </Suspense>
        ),
      },
      {
        path: "/unauthorized",
        element: <UnauthorizedPage />,
      },
    ],
  },
];

export const router = createBrowserRouter(routes);
