import React from "react";

export const LightningIcon = () => {
  return (
    <svg
      width="16"
      height="20"
      viewBox="0 0 16 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.81278 19.9422C4.87478 19.9811 4.94432 20 5.01328 20C5.11934 20 5.22405 19.9554 5.298 19.8705L15.1615 8.5446C15.2585 8.43305 15.2816 8.2751 15.2203 8.1405C15.159 8.00593 15.0248 7.91954 14.8769 7.91954H7.81188L11.0799 0.529846C11.1549 0.360239 11.0955 0.161288 10.9398 0.0605554C10.7841 -0.0402196 10.5782 -0.012846 10.4542 0.125028L0.0967261 11.6449C-0.00287515 11.7557 -0.0279431 11.9147 0.0326728 12.0508C0.0933306 12.1868 0.228354 12.2745 0.377294 12.2745H7.75432L4.66673 19.474C4.59349 19.6447 4.65545 19.8434 4.81278 19.9422ZM13.5596 9.23516L13.5599 9.23472H7.14045L7.14016 9.23516H13.5596ZM3.27811 9.23516H4.24857L4.24828 9.23472H3.2785L3.27811 9.23516Z"
        fill="#00FF02"
      />
    </svg>
  );
};

export const LockIcon = () => {
  return (
    <svg
      width="13"
      height="16"
      viewBox="0 0 13 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.4 5.6V4C10.4 1.76 8.64 0 6.4 0C4.16 0 2.4 1.76 2.4 4V5.6C1.04 5.6 0 6.64 0 8V13.6C0 14.96 1.04 16 2.4 16H10.4C11.76 16 12.8 14.96 12.8 13.6V8C12.8 6.64 11.76 5.6 10.4 5.6ZM4 4C4 2.64 5.04 1.6 6.4 1.6C7.76 1.6 8.8 2.64 8.8 4V5.6H4V4ZM7.2 12C7.2 12.48 6.88 12.8 6.4 12.8C5.92 12.8 5.6 12.48 5.6 12V9.6C5.6 9.12 5.92 8.8 6.4 8.8C6.88 8.8 7.2 9.12 7.2 9.6V12Z"
        fill="#00FF02"
      />
    </svg>
  );
};

export const TgStarIcon = () => {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_i_3654_3738)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5.67984 10.1643L2.97934 11.894C2.69853 12.0738 2.33144 11.9816 2.15943 11.688C2.07541 11.5446 2.05037 11.3718 2.08996 11.2089L2.508 9.48862C2.6589 8.86761 3.06536 8.34853 3.61781 8.0712L6.56393 6.59236C6.70127 6.52341 6.75916 6.35112 6.69322 6.20752C6.63982 6.09122 6.51904 6.02614 6.39746 6.04815L3.11806 6.64173C2.45144 6.76239 1.76782 6.56993 1.24928 6.11562L0.213291 5.20792C-0.0391025 4.98678 -0.0722428 4.5936 0.139271 4.32972C0.242143 4.20137 0.390076 4.1217 0.54975 4.10864L3.71501 3.84965C3.93863 3.83135 4.1335 3.6834 4.21935 3.46675L5.44045 0.384968C5.56648 0.0668927 5.91528 -0.0841405 6.21951 0.0476251C6.36558 0.110894 6.48165 0.232239 6.54217 0.384968L7.76327 3.46675C7.8491 3.6834 8.04398 3.83135 8.2676 3.84965L11.4503 4.11006C11.7786 4.13692 12.0238 4.43694 11.9982 4.78019C11.9858 4.9453 11.9111 5.09849 11.7906 5.20594L9.36333 7.3694C9.19237 7.52171 9.11789 7.76145 9.17039 7.9897L9.91662 11.2307C9.99369 11.5654 9.79662 11.9021 9.47652 11.9827C9.32264 12.0214 9.16039 11.9946 9.02547 11.9081L6.30278 10.1643C6.11163 10.0419 5.87097 10.0419 5.67984 10.1643Z"
          fill="url(#paint0_linear_3654_3738)"
        />
      </g>
      <path
        d="M5.59894 10.038L5.59893 10.038L2.89845 11.7677C2.6919 11.8999 2.41934 11.8349 2.28885 11.6122C2.2247 11.5027 2.20521 11.3699 2.23572 11.2443L2.65376 9.52404L2.508 9.48862L2.65376 9.52404C2.79466 8.94417 3.17352 8.46207 3.68511 8.20526L6.63122 6.72641C6.84411 6.61955 6.92764 6.35855 6.82954 6.14492L6.69322 6.20752L6.82954 6.14492C6.74901 5.96956 6.56307 5.86574 6.37075 5.90055C6.37075 5.90055 6.37074 5.90055 6.37074 5.90055L3.09135 6.49413C2.47061 6.60648 1.83301 6.42761 1.34813 6.00279L0.312141 5.0951C0.120908 4.92755 0.0943697 4.62557 0.256311 4.42354L0.256315 4.42353C0.33417 4.3264 0.444637 4.26774 0.561978 4.25814L0.561982 4.25814L3.72724 3.99915C4.01048 3.97598 4.25296 3.78911 4.3588 3.52201L5.5799 0.440224L5.44045 0.384968L5.5799 0.440223C5.67647 0.196515 5.93762 0.089002 6.15989 0.18527C6.26821 0.232182 6.35632 0.323131 6.40272 0.440226L7.62381 3.52201C7.72964 3.78911 7.97213 3.97598 8.25537 3.99915L11.4381 4.25956C11.6781 4.2792 11.8686 4.50174 11.8486 4.76899L11.8486 4.76902C11.8391 4.8964 11.7815 5.01309 11.6908 5.09396C11.6907 5.09397 11.6907 5.09397 11.6907 5.09397L9.26354 7.25741C9.05049 7.44722 8.95979 7.7433 9.02421 8.02332L9.02421 8.02335L9.77044 11.2644C9.83043 11.5249 9.6756 11.7779 9.4399 11.8372L9.43989 11.8372C9.32664 11.8657 9.20687 11.8462 9.10639 11.7818L9.10637 11.7818L6.38368 10.038L6.38367 10.038C6.14322 9.88405 5.83939 9.88405 5.59894 10.038Z"
        stroke="#C79745"
        strokeWidth="0.3"
      />
      <defs>
        <filter
          id="filter0_i_3654_3738"
          x="0"
          y="0"
          width="13"
          height="13"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="1" dy="1" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="shape"
            result="effect1_innerShadow_3654_3738"
          />
        </filter>
        <linearGradient
          id="paint0_linear_3654_3738"
          x1="3.92308"
          y1="3.84"
          x2="10.518"
          y2="10.1813"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F6EC7D" />
          <stop offset="1" stopColor="#E08E00" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export const WhitePickaxeIcon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="4.49146"
        y="1.7666"
        width="1.70112"
        height="1.27584"
        transform="rotate(90 4.49146 1.7666)"
        fill="white"
      />
      <rect
        x="13.8262"
        y="0.493164"
        width="4.2528"
        height="9.35617"
        transform="rotate(90 13.8262 0.493164)"
        fill="white"
      />
      <rect
        x="12.5503"
        y="1.76855"
        width="1.70112"
        height="8.08033"
        transform="rotate(90 12.5503 1.76855)"
        fill="white"
      />
      <rect
        x="3.40845"
        y="16.5293"
        width="2.97696"
        height="2.97696"
        transform="rotate(90 3.40845 16.5293)"
        fill="white"
      />
      <rect
        x="9.36206"
        y="10.5771"
        width="2.97696"
        height="2.97696"
        transform="rotate(90 9.36206 10.5771)"
        fill="white"
      />
      <rect
        x="6.38428"
        y="13.5518"
        width="2.97696"
        height="2.97696"
        transform="rotate(90 6.38428 13.5518)"
        fill="white"
      />
      <rect
        x="12.3391"
        y="7.60156"
        width="2.97696"
        height="2.97696"
        transform="rotate(90 12.3391 7.60156)"
        fill="white"
      />
      <rect
        x="4.68408"
        y="15.2568"
        width="2.97696"
        height="2.97696"
        transform="rotate(90 4.68408 15.2568)"
        fill="white"
      />
      <rect
        x="10.6389"
        y="9.30078"
        width="2.97696"
        height="2.97696"
        transform="rotate(90 10.6389 9.30078)"
        fill="white"
      />
      <rect
        x="7.66187"
        y="12.2783"
        width="2.97696"
        height="2.97696"
        transform="rotate(90 7.66187 12.2783)"
        fill="white"
      />
      <rect
        x="13.6147"
        y="6.3252"
        width="2.97696"
        height="2.97696"
        transform="rotate(90 13.6147 6.3252)"
        fill="white"
      />
      <rect
        x="15.3159"
        y="4.74414"
        width="2.85615"
        height="2.97696"
        transform="rotate(90 15.3159 4.74414)"
        fill="white"
      />
      <rect
        x="18.2839"
        y="1.7666"
        width="2.97696"
        height="2.97696"
        transform="rotate(90 18.2839 1.7666)"
        fill="white"
      />
      <rect
        x="13.8262"
        y="1.76855"
        width="1.70112"
        height="1.27584"
        transform="rotate(90 13.8262 1.76855)"
        fill="white"
      />
      <rect
        x="15.3105"
        y="3.47949"
        width="1.27584"
        height="1.70112"
        transform="rotate(90 15.3105 3.47949)"
        fill="white"
      />
      <rect
        x="13.8262"
        y="3.46777"
        width="1.28568"
        height="1.27584"
        transform="rotate(90 13.8262 3.46777)"
        fill="white"
      />
      <rect
        width="1.70112"
        height="1.27584"
        transform="matrix(-1 0 0 1 18.2964 15.4492)"
        fill="white"
      />
      <rect
        width="4.2528"
        height="9.35617"
        transform="matrix(-1 0 0 1 19.5686 6.11426)"
        fill="white"
      />
      <rect
        width="1.7195"
        height="1.48473"
        transform="matrix(-1 0 0 1 18.2998 4.7373)"
        fill="white"
      />
      <rect
        width="1.90922"
        height="1.59102"
        transform="matrix(-8.42937e-08 1 1 8.42937e-08 13.7166 1.7666)"
        fill="white"
      />
      <rect
        width="1.70112"
        height="8.08033"
        transform="matrix(-1 0 0 1 18.2954 7.38965)"
        fill="white"
      />
      <rect
        width="1.70112"
        height="1.27584"
        transform="matrix(-1 0 0 1 18.2944 6.11426)"
        fill="white"
      />
      <rect
        width="1.27584"
        height="1.58565"
        transform="matrix(-1 0 0 1 16.5828 4.74609)"
        fill="white"
      />
      <rect
        width="1.28668"
        height="1.27584"
        transform="matrix(-1 0 0 1 16.5952 6.11426)"
        fill="white"
      />
      <rect
        x="15.3071"
        y="4.63086"
        width="1.59102"
        height="1.59102"
        transform="rotate(90 15.3071 4.63086)"
        fill="white"
      />
    </svg>
  );
};
