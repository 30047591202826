import React, { useEffect, useState } from "react";
import cn from "classnames";
import { useNavigate } from "react-router-dom";
import { ArrowIcon } from "@/components/tasks/icons";
import { WhitePickaxeIcon } from "@/assets/icons";
import { useTariffsDataContext } from "@/store/TariffsProvider";
import cls from "./styles.module.scss";

export const MiningButton: React.FC = () => {
  const navigate = useNavigate();
  const { activeTariffs, total, onClaim } = useTariffsDataContext();
  const [showBalance, setShowBalance] = useState(false);

  useEffect(() => {
    const interval = setInterval(() => {
      setShowBalance((p) => !p);
    }, 5000);

    return () => clearInterval(interval);
  }, []);

  const hasTariffs = activeTariffs.length > 0;
  const perHour = activeTariffs.reduce((acc, i) => {
    return acc + i.tariff.count_gen;
  }, 0);

  if (!hasTariffs)
    return (
      <button
        type="button"
        className={cn(cls.button)}
        onClick={() => navigate("/tariffs")}
      >
        <div className={cn(cls.arrowWrapper)}>
          <div className={cls.arrow}>
            <ArrowIcon />
          </div>
        </div>
        <div className={cls.text}>Mining</div>
      </button>
    );

  return (
    <button
      type="button"
      className={cn(cls.button, cls.mining)}
      onClick={onClaim}
    >
      <div
        className={cls.stripesWrapper}
        style={{
          width: `${
            (total.total_accrued_tokens / total.max_daily_tokens) * 100
          }%`,
        }}
      >
        <div className={cls.stripes} />
      </div>

      <div className={cn(cls.pickaxe)}>
        <WhitePickaxeIcon />
      </div>
      <div className={cls.textWrapper}>
        <div className={cls.miningText}>
          {total.total_accrued_tokens > 0 ? "Claim" : "Mining"}
        </div>
        <div className={cls.gens}>
          {showBalance
            ? `${Number(total.total_accrued_tokens.toFixed(3))} / ${Number(
                total.max_daily_tokens.toFixed(3)
              )}`
            : `${Number(perHour.toFixed(3))} $GEN / hour`}
        </div>
      </div>
    </button>
  );
};
