import React, { useEffect, useState } from "react";
import { ProgressBar } from "@/features/ProgressBar/ProgressBar";
import { Balance } from "@/components/Balance/Balance";
import { ActionButtons } from "@/components/ActionButtons/ActionButtons";
import { LogoMain } from "@/components/Logo/Logo";
import { useDataContext } from "@/store/DataProvider";
import { removePreloader } from "@/utils/helpers";
import { Buttons } from "@/features/main/Buttons/Buttons";
import { Loader } from "@/components/Loader/Loader";

const MainPage = () => {
  const { dataLoading } = useDataContext();

  const [preloaderRemoved, setPreloaderRemoved] = useState(false);

  useEffect(() => {
    if (!dataLoading && !preloaderRemoved) {
      removePreloader();
      setPreloaderRemoved(true);
    }
  }, [dataLoading, preloaderRemoved]);

  if (dataLoading) return <Loader />;

  return (
    <>
      <ProgressBar />
      <Balance />
      <LogoMain />
      <ActionButtons />
      <Buttons />
    </>
  );
};

export default MainPage;
