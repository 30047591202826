// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dTJsdKBO:active{color:#000}.w1cLIJhW{display:flex;flex-direction:column;gap:1rem}.SBHw8SGZ{font-size:1.25rem;margin-bottom:1rem;color:#fff}.LLaZS_zk{border-radius:.5rem;text-align:center;background:#fff;width:100%;padding:1rem;transition:.2s}.LLaZS_zk:active{background:#cecece;transition:.1s}`, "",{"version":3,"sources":["webpack://./src/styles/components/InviteButton.module.scss"],"names":[],"mappings":"AAIA,iBACE,UAAA,CAGF,UACE,YAAA,CACA,qBAAA,CACA,QAAA,CAGF,UACE,iBAAA,CACA,kBAAA,CACA,UAAA,CAGF,UACE,mBAAA,CACA,iBAAA,CACA,eAAA,CACA,UAAA,CACA,YAAA,CACA,cAAA,CAGF,iBACE,kBAAA,CACA,cAAA","sourcesContent":[".root {\n\n}\n\n.root:active {\n  color: #000000;\n}\n\n.modal {\n  display: flex;\n  flex-direction: column;\n  gap: 1rem\n}\n\n.title {\n  font-size: 1.25rem;\n  margin-bottom: 1rem;\n  color: #FFFFFF;\n}\n\n.btn {\n  border-radius: 0.5rem;\n  text-align: center;\n  background: #FFFFFF;\n  width: 100%;\n  padding: 1rem;\n  transition: 0.2s;\n}\n\n.btn:active {\n  background: #CECECE;\n  transition: 0.1s;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `dTJsdKBO`,
	"modal": `w1cLIJhW`,
	"title": `SBHw8SGZ`,
	"btn": `LLaZS_zk`
};
export default ___CSS_LOADER_EXPORT___;
