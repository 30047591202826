import React from "react";
import { InviteButton } from "@/components/InviteButton/InviteButton";
import { MiningButton } from "@/components/MiningButton/MiningButton";
import cls from "./styles.module.scss";

export const Buttons = () => {
  return (
    <div className={cls.wrapper}>
      <div className={cls.mining}>
        <MiningButton />
      </div>
      <div className={cls.invite}>
        <InviteButton fixWidth />
      </div>
    </div>
  );
};
