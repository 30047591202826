// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.HS2UqaaZ{display:flex;flex-direction:row;gap:.5rem;margin:0 1rem 0}.ERFijQjm{flex:1}.RPuxn6JF{width:8rem}`, "",{"version":3,"sources":["webpack://./src/features/main/Buttons/styles.module.scss"],"names":[],"mappings":"AAAA,UACE,YAAA,CACA,kBAAA,CACA,SAAA,CACA,eAAA,CAGF,UACE,MAAA,CAGF,UACE,UAAA","sourcesContent":[".wrapper {\n  display: flex;\n  flex-direction: row;\n  gap: 0.5rem;\n  margin: 0 1rem 0;\n}\n\n.mining {\n  flex: 1\n}\n\n.invite {\n  width: 8rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `HS2UqaaZ`,
	"mining": `ERFijQjm`,
	"invite": `RPuxn6JF`
};
export default ___CSS_LOADER_EXPORT___;
