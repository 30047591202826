import $api from "@/api/index";

export type Tariff = {
  id: number;
  title: string;
  count_stars: number;
  count_gen: number;
  previous_count_stars: number;
};

export type ActiveTariffT = {
  id: number;
  title: string;
  count_stars: number;
  count_gen: number;
  created_at: string;
  finish_at: string;
  accrued_tokens: number;
  max_token: number;
  previous_count_stars: number;
};

export type UserTariff = {
  id: number;
  tariff: ActiveTariffT;
};

export type TotalTariffTokens = {
  max_daily_tokens: number;
  total_accrued_tokens: number;
};

export type TariffResponse = {
  available_tariffs: Tariff[];
  chat_id: string;
  user_tariff: UserTariff[];
  max_daily_tokens: number;
  total_accrued_tokens: number;
};

export async function getTariffs(): Promise<TariffResponse> {
  const res = await $api.get("/stars/user-tariffs/");

  return res.data;
}

export async function claimTokens(): Promise<{ tokens_claimed: number }> {
  const res = await $api.get("/stars/user-tokens/claim/");

  return res.data;
}

export async function getPaymentLink(
  tariffId: number
): Promise<{ payment_link: string }> {
  const res = await $api.post("/stars/create-payment-link/", {
    tariff: tariffId,
  });

  return res.data;
}
