import React, { createContext, useContext } from "react";
import { BalanceI, useBalance } from "@/api/balance";
import { ProgressBarI, useProgressBar } from "@/api/progressBar";
import { InviteLinkI, useInviteLink } from "@/api/invite";

type DataContextType = {
  balance: BalanceI | null;
  loadBalance: (ignoreLoader?: boolean) => Promise<void>;
  link: InviteLinkI | null;
  progressBarData: ProgressBarI | null;
  dataLoading: boolean;
};

const DataContext = createContext<DataContextType | undefined>(undefined);

export const DataProvider = ({ children }: { children: React.ReactNode }) => {
  const [balance, balanceLoading, loadBalance] = useBalance();
  const [progressBarData, progressBarLoading] = useProgressBar();
  const [link, linkLoading] = useInviteLink();

  const dataLoading = balanceLoading || progressBarLoading || linkLoading;

  return (
    <DataContext.Provider
      value={{
        balance,
        progressBarData,
        dataLoading,
        link,
        loadBalance,
      }}
    >
      {children}
    </DataContext.Provider>
  );
};

export const useDataContext = () => {
  const context = useContext(DataContext);
  if (!context) {
    throw new Error("useDataContext must be used within a DataProvider");
  }
  return context;
};
