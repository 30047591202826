import React from "react";
import { StakeIcon, WithdrawIcon } from "@/components/ActionButtons/icons";
import cls from "@/styles/components/ActionButtons.module.scss";
import { useNavigate } from "react-router-dom";

type Props = {
  text: string;
  onClick: () => void;
  children: React.ReactNode;
};

const ActionButton: React.FC<Props> = ({ text, onClick, children }) => {
  return (
    <div className={cls.btnWrapper}>
      <button type="button" className={cls.btn} onClick={onClick}>
        {children}
      </button>
      <div className={cls.btnText}>{text}</div>
    </div>
  );
};

export const ActionButtons = () => {
  const navigate = useNavigate();
  return (
    <div className={cls.root}>
      <ActionButton text="Out" onClick={() => navigate("/out")}>
        <WithdrawIcon />
      </ActionButton>
      <div className={cls.divider} />
      <ActionButton text="Stake" onClick={() => null}>
        <StakeIcon />
      </ActionButton>
    </div>
  );
};
