import React, { createContext, useContext, useEffect, useState } from "react";
import {
  claimTokens,
  getTariffs,
  Tariff,
  TotalTariffTokens,
  UserTariff,
} from "@/api/tariffs";
import { Loader } from "@/components/Loader/Loader";
import { useAuth } from "@/store/auth";
import { showAlert } from "@/utils/network";
import { useDataContext } from "@/store/DataProvider";

type TariffsDataContextType = {
  availableTariffs: Tariff[];
  activeTariffs: UserTariff[];
  isLoading: boolean;
  isClaiming: boolean;
  reloadTariffs: (showLoader?: boolean) => void;
  selectedTariff: Tariff | null;
  setSelectedTariff: (selectedTariff: Tariff) => void;
  total: TotalTariffTokens | null;
  onClaim: () => void;
};

const TariffsDataContext = createContext<TariffsDataContextType | undefined>(
  undefined
);

export const TariffsDataProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const { token } = useAuth();
  const { loadBalance } = useDataContext();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [availableTariffs, setAvailableTariffs] = useState<Tariff[]>([]);
  const [activeTariffs, setActiveTariffs] = useState<UserTariff[]>([]);
  const [selectedTariff, setSelectedTariff] = useState<Tariff>();
  const [total, setTotal] = useState<TotalTariffTokens>();
  const [isClaiming, setIsClaiming] = useState(false);

  const getData = async (showLoader?: boolean) => {
    if (!token) return;
    if (showLoader) setIsLoading(true);

    try {
      const res = await getTariffs();
      setAvailableTariffs(res.available_tariffs);
      if (res.available_tariffs.length > 0)
        setSelectedTariff(res.available_tariffs[0]);
      setActiveTariffs(res.user_tariff);
      setTotal({
        max_daily_tokens: res.max_daily_tokens,
        total_accrued_tokens: res.total_accrued_tokens,
      });
    } catch (e) {
      // console.log(e);
    } finally {
      setIsLoading(false);
    }
  };

  const onClaim = async () => {
    setIsClaiming(true);
    try {
      const r = await claimTokens();
      const amount = r.tokens_claimed;
      showAlert({
        text:
          amount > 0
            ? `Claimed +${amount.toFixed(3)} $GEN!`
            : "No tokens to claim!",
        type: "success",
      });
      await loadBalance();
      await getData();
    } catch (e) {
      // console.log(e);
    } finally {
      setIsClaiming(false);
    }
  };

  useEffect(() => {
    getData(true);
  }, [token]);

  useEffect(() => {
    const interval = setInterval(() => {
      getData();
    }, 5000);

    return () => clearInterval(interval);
  }, []);

  if (isLoading) return <Loader />;

  return (
    <TariffsDataContext.Provider
      value={{
        availableTariffs,
        activeTariffs,
        selectedTariff,
        setSelectedTariff,
        isLoading,
        reloadTariffs: getData,
        total,
        isClaiming,
        onClaim,
      }}
    >
      {children}
    </TariffsDataContext.Provider>
  );
};

export const useTariffsDataContext = () => {
  const context = useContext(TariffsDataContext);
  if (!context) {
    throw new Error(
      "useTariffsDataContext must be used within a TariffsDataProvider"
    );
  }
  return context;
};
