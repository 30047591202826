import $api from "@/api/index";
import { useAuth } from "@/store/auth";

export const getAuthToken = async (initData: string) => {
  const response = await $api.post("/get_auth_token", {
    init_data: initData,
  });

  useAuth.getState().setToken(response.data.token);
};

export const completeOnboarding = async (initData: string) => {
  const response = await $api.post("/user/check_onboarding/", {
    init_data: initData,
  });
};
