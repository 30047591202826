import "@/styles/base.scss";
import React, { FC, useEffect } from "react";
import cls from "@/styles/components/App.module.scss";
import { Outlet } from "react-router-dom";
import { Alert } from "@/components/Alert/Alert";
import { TonConnectUIProvider } from "@tonconnect/ui-react";
import {
  useExpand,
  useInitData,
  useWebApp,
} from "@vkruglikov/react-telegram-web-app";
import { Nav } from "@/features/Nav/Nav";
import { useAuth } from "@/store/auth";
import { UserDataProvider } from "@/store/UserDataProvider";
import { DataProvider } from "@/store/DataProvider";
import { Onboarding } from "@/components/Onboarding/Onboarding";
import { TariffsDataProvider } from "@/store/TariffsProvider";

export const App: FC = () => {
  const webapp = useWebApp();
  const [isExpanded, expand] = useExpand();
  const [initDataUnsafe] = useInitData();

  const { token } = useAuth();

  useEffect(() => {
    if (!isExpanded) {
      expand();
    }
  }, []);

  useEffect(() => {
    webapp.disableVerticalSwipes();
  }, []);

  return (
    <TonConnectUIProvider
      manifestUrl={`${window.location.origin}/tonconnect-manifest.json`}
    >
      <UserDataProvider>
        <DataProvider>
          <TariffsDataProvider>
            <div className={cls.wrapper}>
              <div className={cls.id}>
                Telegram id: {initDataUnsafe.user?.id}
              </div>
              <div className={cls.container}>
                <Outlet />
                {token && <Nav />}
                <Onboarding />
              </div>
              <Alert />
            </div>
          </TariffsDataProvider>
        </DataProvider>
      </UserDataProvider>
    </TonConnectUIProvider>
  );
};
